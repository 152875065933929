import React from 'react'
import CTA from '../CTA'
import config from '../../../config'
import styles from './GrowWithUsCTA.module.css'
import Gtag from '../../../analytics'

const {
    links
} = config

const link = links.find(link => link.href.includes('grow'))

if(!link) throw Error('Couldnt find Grow With Us link, please update GrowWithUsCTA')

export default ({ children }) => {

    const trackEvent = () => {
        Gtag.trackEvent('CLICK_GROW_WITH_US', {
            'path': window.location.href
          });
    }

    return (
        <CTA
            href={link.href}
            onClick={trackEvent}
            className={styles.growWithUsCTA}
        >
            GROW WITH US
        </CTA>
    )
}