import React from 'react'

import styles from './index.module.css'
import '../style.module.css'

import Skeleton from '../components/Skeleton'
import GrowWithUsCTA from '../components/common/GrowWithUsCTA'
import Carousel from '../components/Carousel'
import config from '../config'

class Home extends React.Component {
  render() {
    const {
      home: {
        bullets,
        carousel: {
          title: carouselTitle,
          hash: carouselHash,
          carouselCards,
        },
        boxCTA: {
          title: boxTitle,
          description: boxDescription,
          hash: boxHash,
        },
        text: {
          valuesTitle,
          valuesSubTitle,
          investorsTitle,
        },
        bannerImage
      },
      investors,
    } = config

    return (
      <Skeleton
        logoInHero={true}
        bannerImage={bannerImage}
      >
              <GrowWithUsCTA />
              <div className={styles.columnsContainer}>
                <div className={styles.columnLeft}>
                  <h2 className={styles.leftColumnHeader}> {valuesTitle} </h2>
                  <p className={styles.leftColumnText}>{valuesSubTitle}</p>
                </div>
                <div className={styles.columnRight}>
                  {
                    bullets.map(item => (
                      <div className={styles.bulletContainer} key={item.title}>
                        <div className={styles.bulletHeaderContainer}>
                          <span className={styles.bullet} /> 
                          <h3 className={styles.bulletHeader}> {item.title} </h3>
                        </div>
                        <p className={styles.bulletDescription}>{item.description}</p>
                      </div>
                    ))
                  }
                </div>
              </div>

              <h2 id={carouselHash} className={styles.investorsHeader}> {carouselTitle} </h2>
              <Carousel cards={carouselCards} />

              <h2 className={styles.investorsHeader}> {investorsTitle} </h2>
              <div className={styles.investorsImagesContainer}>
                {
                  investors.map(img => (
                    <img 
                      className={styles.investorImg} 
                      loading="lazy" 
                      src={img.src} 
                      alt={img.alt} 
                      width={256}
                      height={78}
                      key={img.src}
                    />
                  ))
                }
              </div>

              <div id={boxHash} className={styles.boxedMessageContainer}>
                <div className={styles.boxedMessage}>
                  <div className={styles.leftColumn}>
                    <h2 className={styles.leftColumnHeader}> {boxTitle} </h2>
                    <p className={styles.leftColumnText}>
                      {boxDescription}
                    </p>
                  </div>
                  <div className={`${styles.columnRight} ${styles.buttonContainer} `}>
                    <GrowWithUsCTA />
                  </div>
                </div>
              </div>
      </Skeleton>
    )
  }
}

export default Home
