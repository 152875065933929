/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { PrevButton, NextButton } from "./CarouselButtons"; 
import useEmblaCarousel from "embla-carousel-react";
import "./embla.css";


const EmblaCarousel = ({ cards = [] }) => {
  const mediaByIndex = index => cards[index % cards.length];

  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    align: 'center',
    loop: true
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <div className="embla">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {cards.map(({ brandName, logo, src }, index) => (
            <div className="embla__slide" key={brandName + index}>
              <div className="embla__slide__inner">
                <div className="brand_text">
                    {
                        logo ?
                        <img 
                            src={logo} 
                            alt={`${brandName} logo`}
                            loading="lazy"
                            className="carousel__company-logo" />
                        :
                        null
                    }
                </div>
                <img
                  className="embla__slide__img"
                  loading="lazy"
                  src={mediaByIndex(index)['src']}
                  alt={mediaByIndex(index)['brandName']}
                  key={mediaByIndex(index)['brandName'] + index}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default EmblaCarousel;
